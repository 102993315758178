import { configureStore } from '@reduxjs/toolkit';
import Guest from './guest';
import Produk from './produk';
import Member from './Member';
import Fasilitator from './Fasilitator';
import Admin from './Admin';
const store = configureStore({
    reducer:{
        Guest:Guest.reducer,
        Produk:Produk.reducer,
        Member:Member.reducer,
        Fasilitator:Fasilitator.reducer,
        Admin:Admin.reducer,
    }
});

export default store;