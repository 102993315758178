import React,{useState} from 'react'
import {Button, Card, CardContent, CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography} from '@mui/material'
import { Box } from '@mui/system'
import {VisibilityOffOutlined, VisibilityOutlined} from '@mui/icons-material';
import {useDispatch} from 'react-redux';
import { loginAdmin } from '../store/Admin';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const members = {
      username:'',
      password:''
  }

  const [eyeOpen,setEyeOpen] = useState(false);

  // const isLogged = useSelector(state => state.auth.isLoggedIn);

  const [member,setMember] = useState(members);

  const [loading,setLoading] = useState(false);

  // useEffect(()=>{
  //   isLogged === true && localStorage.getItem('username')  ? navigate('/') : navigate('/loginMember');
  // },[isLogged]);
  
  const loginAuth = async(e) =>{
    e.preventDefault();
    setLoading(true);
    await dispatch(loginAdmin(member)).then(()=>{
        setLoading(false);
        navigate('/');
        localStorage.setItem('isLogin',true);
    });
    
    //isLogged === true ? Navigate('/') : Navigate('/login');
  }
  return (
    <Box sx={{ display:'flex', justifyContent:'center' }}>
        <Card sx={{ zIndex: 1, maxWidth:450 }}>
          <CardContent>
          <Box sx={{ mb: 6, justifyContent: 'center', alignItems: 'center', display: 'flex', }}>
          <img src={require('../assets/orbit.png')} alt="Logo" style={{ width: '40vh', height: '120px' }} />
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant='h5' sx={{ mb: 1.5, mt: 2, fontWeight: 800, letterSpacing: '0.18px' }}>
            {`Welcome to Login 👋🏻`}
          </Typography>
        </Box>
            <form onSubmit={loginAuth}>
              <TextField
                id=""
                label="Username"
                fullWidth
                sx={{mb:4}}
                onChange = {(event)=>setMember({...setMember,username:event.target.value})}
              />
              <FormControl fullWidth>
                <InputLabel htmlFor='auth-login-password'>Password </InputLabel>
                  <OutlinedInput type={ eyeOpen ? 'text' : 'password' } label='Password' onChange = {(event)=>setMember({...member,password:event.target.value})} endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            edge='end'
                            aria-label='toggle password visibility'
                            onClick={()=>setEyeOpen(!eyeOpen)}
                          >
                            {eyeOpen ? <VisibilityOutlined /> : <VisibilityOffOutlined /> }
                          </IconButton>
                        </InputAdornment>
                      } />
              </FormControl>
              <Button fullWidth size='large' type='submit' variant='contained' sx={{ mb: 7, mt:4 }} disabled={ loading } > {loading ? <CircularProgress /> : ''} LOGIN</Button>
            </form>
            
          </CardContent>
      </Card>
    </Box>
    
  )
}

export default Login