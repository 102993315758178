import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import qs from 'qs';

export const loginAdmin = (data) => async(dispatch)=> {
    const res = await axios.post(`${process.env.REACT_APP_URL}admin/login_admin`,qs.stringify(data));
    return await dispatch(setLogin(res.data.login));
    //console.log(typeof(res.data.login));
}

export const authLogin = (value) => async(dispatch)=>{
    await dispatch(setLogin(value))
}


export const getAbout = ()=>async(dispatch)=>{
    const res = await axios.get(`${process.env.REACT_APP_URL}admin/get_about`);
    return await dispatch(setAbout(res.data));
}

export const createAbout = (data)=>async(dispatch)=>{
    let row = new FormData();
    row.append('deskripsi',data.deskripsi);
    row.append('foto',data.foto);
    await axios.post(`${process.env.REACT_APP_URL}admin/create_about`,row);
    return;
}
export const editABout = (data)=>async(dispatch)=>{
    let row = new FormData();
    row.append('deskripsi',data.deskripsi);
    row.append('foto',data.foto);
    await axios.post(`${process.env.REACT_APP_URL}admin/edit_about`,row);
    return;
}


export const getNews = () => async(dispatch) =>{
    const res = await axios.get(`${process.env.REACT_APP_URL}admin/get_berita`);
    await dispatch(setNews(res.data));
}

export const createBerita = (data) => async(dispatch) =>{
    let row = new FormData();
    row.append('judul',data.judul);
    row.append('foto_berita',data.foto_berita);
    row.append('isi_berita',data.isi_berita);
    row.append('link_video',data.link_video);
    await axios.post(`${process.env.REACT_APP_URL}admin/create_berita`,row);
    return;
}


export const editBerita = (data) => async(dispatch) =>{
    // console.log(data);
    let row = new FormData();
    row.append('id_berita',data.id_berita);
    row.append('judul',data.judul);
    row.append('foto_berita',data.foto_berita);
    row.append('isi_berita',data.isi_berita);
    row.append('link_video',data.link_video);
    await axios.post(`${process.env.REACT_APP_URL}admin/update_berita`,row);
    return;
}

export const deteleBerita = (id_berita) => async(dispatch)=>{
    try {
        await axios.delete(`${process.env.REACT_APP_URL}admin/delete_berita/${id_berita}`);
        await dispatch(setDeleteBerita(id_berita));
    } catch (error) {
        console.log(error);
    }
}

const Admin = createSlice({
    name:'admin',
    initialState:{
        isLoggedIn: false,
        about:[],
        news:[]
    },
    reducers:{
        setLogin(state,actions){
            state.isLoggedIn = actions.payload;
        },
        setAbout(state,actions){
            state.about = actions.payload;
        },
        setNews(state,actions){
            state.news = actions.payload;
        },
        setDeleteBerita(state,action){
            let filNews = state.news.filter(row=> parseInt(row.id_berita) !== parseInt(action.payload));
            state.news = filNews;
        }
    }
});


const {setLogin,setAbout,setNews,setDeleteBerita} = Admin.actions;

export default Admin;