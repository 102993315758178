import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, TextField, Typography } from '@mui/material'
import React,{useEffect, useState} from 'react'
// import { AddAPhoto } from '@mui/icons-material';
// import InputRupiah from '../component/InputRupiah';
import { useDispatch, useSelector } from 'react-redux';
import { createBerita,editBerita } from '../store/Admin';
import { useNavigate,useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


//===========================react quill==================================
const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'align',
    'list',
    'link',
    // 'image',
  ];
  
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'align': [] }], // toolbar rata kanan dan rata kiri
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link', 'image'],
      ['clean'],
    ],
  };
//=================================================================

const ProdukAdd = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [berita,setBerita] = useState(
                                    {
                                    judul:'',
                                    isi_berita:'',
                                    foto_berita:'',
                                    link_video:''
                                   }
                                ); 
    const [loading,setLoading] = useState(false);
    
    const createNews = async(e) =>{
        e.preventDefault();
        await setLoading(true);
        return new Promise((resolve, reject) =>{
            dispatch(createBerita(berita)).then(()=>{
                setLoading(false);
                resolve();
            })
            .catch((error)=>{
               reject(error); 
            })
            .finally(()=>{
                navigate('/news');
            })
        })
    }

    //======================================edit ========================================
    
    const handleEditBerita = async(e) =>{
        e.preventDefault();
        await setLoading(true);
        berita.id_berita = await params.id_berita;
        await dispatch(editBerita(berita));
        await setLoading(false);
        navigate('/news');
    }

    // useEffect(() => {
    //     const fetchData = async () => {
    //       await dispatch(getProduct());
    //     };
    //     fetchData();
    //   }, [dispatch]);

    const news = useSelector(state=>state.Admin.news);

    useEffect(() => {
        if(params.id_berita){
            const findIdBerita = news.find(row => parseInt(row.id_berita) === parseInt(params.id_berita));
            setBerita(prev => ({
                ...prev,
                id_berita:params.id_berita,
                judul: findIdBerita.judul,
                isi_berita: findIdBerita.isi_berita,
                link_video: findIdBerita.link_video,
              }));
        }
    }, [news,params]);
   
  return (
    <div>
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <Card>
                    <CardHeader
                    title={params.id_produk ? 'Edit Produk' : "Create News" }
                    subheader=""
                    />
                <Divider></Divider>
                <CardContent>
                <form onSubmit={params.id_berita ? handleEditBerita : createNews}>
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <TextField fullWidth label='Judul' onChange={(e)=>setBerita({...berita,judul:e.target.value})} value={berita.judul}  placeholder='Judul'  />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField fullWidth label='Link Youtube' onChange={(e)=>setBerita({...berita,link_video:e.target.value})} value={berita.link_video}   placeholder='Link video' />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        {/* <TextField multiline rows={5} fullWidth label='Deskripsi Harga/ Cost' value={produk.deskripsi_harga} onChange={(e)=>setProduk({...produk,deskripsi_harga:e.target.value})}  placeholder='Deskripsi Harga / Cost' /> */}
                        <Typography variant='body1'>Isi Berita:</Typography>
                        <ReactQuill
                            formats={formats}
                            modules={modules}
                            value={berita.isi_berita}
                            onChange={(value) => setBerita({ ...berita, isi_berita: value })} 
                            />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField fullWidth helperText="Pilih Foto Berita" onChange={(e)=>setBerita({...berita,foto_berita:e.target.files[0]})} type="file" inputProps={{ accept: '.jpg,.png,.jpeg',}} />
                    </Grid>              
                    <Grid item xs={12}>
                                <Box
                                    sx={{
                                        gap: 5,
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}
                                    >
                                    <Button type='submit' variant='contained' size='large' disabled={loading}>
                                    {loading ? <CircularProgress /> : (params.id_berita ? 'Edit Berita' : "Add Berita" ) }
                                    </Button>
                                </Box>
                        </Grid>
                    </Grid>
                </form>
                
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
    </div>
  )
}

export default ProdukAdd