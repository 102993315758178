import React,{useEffect} from 'react'
import { Typography,Grid, Card, CardContent, CardActions, Button, IconButton, Chip, Divider, Box, Avatar, } from '@mui/material';
import { styled } from '@mui/system';
import { AddBusiness, Delete } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import {useDispatch,useSelector} from 'react-redux';
import { deleteFasilitator, getFasilitator } from '../store/Fasilitator';


const AddButton = styled(Button)(({theme})=>({
    background:theme.palette.primary.main,
    color:theme.palette.custom.white,
    paddingY:theme.spacing(1),
    minWidth:'200px',
    '&:hover': {
        backgroundColor: theme.palette.primary,
      },
    '.IconAdd':{
        color: theme.palette.custom.white
    }
}))

const ChipKategori = styled(Grid)(({theme})=>({
    display:'flex',
    justifyContent:'center',
    marginTop:'0rem',
}))

const StyledChip = styled(Chip)(({theme})=>({
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.custom.white
      },
}))


const Fasilitator = () => {
    
    const dispatch = useDispatch();

    const dataFasilitator = useSelector(state=>state.Fasilitator.fasilitator);

    useEffect(() => {
        dispatch(getFasilitator());
    }, [dispatch]);

    const foto = (foto) =>{
        return process.env.REACT_APP_URL+`assets/foto_fas/${foto}`;
      }

     const handleDelete = async(id_fasilitator)=>{
            const confirmed = window.confirm('Apakah Anda yakin menghapus data?');
            if(confirmed){
                await dispatch(deleteFasilitator(id_fasilitator))
            }
      }


  return (
    <>
        <Grid container flexDirection={'column'} >
            <Grid container spacing={2} justifyContent={'space-between'}>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h4" sx={{mb:4}} color="initial">
                        Fasilitator
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <AddButton component={Link} to={'Fasilitator_add'}  >
                        <IconButton className='IconAdd'>
                            <AddBusiness />
                        </IconButton>
                        Tambah Fasilotator
                    </AddButton>
                </Grid>
            </Grid>
            <Grid container spacing={2} >
                    {dataFasilitator && dataFasilitator.map((row,index) => (
                        <Grid key={row.id_fasilitator} item xs={12} sm={6} md={3}>
                        <Card sx={{ maxWidth: 345 }}>

                            <Box sx={{ position: 'relative',display:'flex',justifyContent:'center',alignItems: 'center',padding:'20px 0 0 0' }}>
                                <Avatar src={foto(row.foto)} alt="foto" sx={{width:150,height:150,}} />
                            </Box>

                            <CardContent>
                            <Typography gutterBottom variant="h6" component="div" sx={{textAlign:"center"}}>
                                    {row.nama}
                                </Typography>
                                <ChipKategori>
                                   <StyledChip label={row.spesialisasi} color="primary" variant="outlined" /> 
                                </ChipKategori>
                            </CardContent>
                            <Divider />
                            <CardActions sx={{display:'flex',justifyContent:'end'}}>
                                {/* <Button
                                  variant="text"
                                  color="primary"
                                  size="small"
                                  startIcon={<Edit />}
                                  
                                >
                                  EDIT
                                </Button> */}
                                <Button variant="text" size="small" onClick={()=>handleDelete(row.id_fasilitator)} startIcon={<Delete />} color="primary"> HAPUS</Button>
                            </CardActions>
                            </Card>
                        </Grid>
                    ))}     
                </Grid>
        </Grid>

    </>
  )
}

export default Fasilitator