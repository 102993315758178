import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
// import qs from 'qs'; 


export const getGuest = () => async(dispatch)=>{
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL}guest/get_guest`);
        dispatch(setGuest(res.data))
    } catch (error) {
        console.log(error);
    }
}

export const get_prospek = () => async(dispatch) =>{
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL}guest/get_prospek`);
        dispatch(setProspek(res.data));
    } catch (error) {
        console.log(error);
    }
}


const Guest = createSlice({
    name:'guest',
    initialState:({guest:[],prospek:[]}),
    reducers:{
        setGuest(state, action) {
            state.guest = action.payload;
          },
        setProspek(state,action){
            state.prospek = action.payload;
        }
    }
});

const {setGuest,setProspek} = Guest.actions;

export default Guest;