import { Box, Button, Card, CardContent, CardHeader, CardMedia, CircularProgress, Divider, Grid, TextField, Typography } from '@mui/material'
import React,{useEffect, useState} from 'react'
// import { AddAPhoto } from '@mui/icons-material';
// import InputRupiah from '../component/InputRupiah';
import { useDispatch, useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { getAbout,createAbout,editABout } from '../store/Admin';
import 'react-quill/dist/quill.snow.css';


import Quill from 'quill';

// Custom align formats
const AlignClass = Quill.import('attributors/class/align');
const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignClass, true);
Quill.register(AlignStyle, true);
//===========================react quill==================================
const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'align',
    'list',
    'link',
    // 'image',
  ];
  
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'align': [] }], // toolbar rata kanan dan rata kiri
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link', 'image'],
      ['clean'],
    ],
  };

//==================================================================================================================

const About = () => {
    const dispatch = useDispatch();
    // const params = useParams();
    const [about,setAbout] = useState(
                                    {
                                    deskripsi:'',
                                    foto:''
                                   }
                                ); 
    const [loading,setLoading] = useState(false);


    // const createRupiah = (data,atribut) =>{
    //                     switch (atribut) {
    //                       case 'harga':
    //                         setProduk({...produk,harga:data});
    //                         break;
    //                       default:
    //                         break;
    //                     }
    //                   }
    
    const handleCreateAbout = async(e) =>{
        e.preventDefault();
        await setLoading(true);
        // console.log(about)
        await dispatch(createAbout(about))
        .then(()=>{
            setLoading(false);
        })
        .catch((error)=>{
          console.log(error)
        });
    }

    //======================================edit ========================================

    const handleEditAbout = async(e) =>{
        e.preventDefault();
        await setLoading(true);
        await dispatch(editABout(about));
        await setLoading(false);
    }

    const aboutData = useSelector(state=>state.Admin?.about);

    useEffect(() => {
        const fetchData = async () => {
          await dispatch(getAbout());
        };
        fetchData();
      }, [dispatch]);
    
    useEffect(()=>{
        if(aboutData.length !== 0)
            setAbout(...aboutData);
    },[aboutData])




   
  return (
    <div>
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <Card>
                    <CardHeader
                    title={ aboutData.length !== 0 ? 'Edit About uS' : "About Us" }
                    subheader=""
                    />
                <Divider></Divider>
                <CardContent>
                <form onSubmit={ aboutData.length === 0 ? handleCreateAbout : handleEditAbout}>
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant='body1'>Deskripsi :</Typography>
                        <ReactQuill
                            formats={formats}
                            modules={modules}
                            value={about.deskripsi}
                            onChange={(value) => setAbout((prevAbout) => ({
                                ...prevAbout,
                                deskripsi: value
                              }))} 
                            sx={{minHeight:'10em'}}
                            />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth helperText="Pilih Foto About" onChange={(e)=>setAbout({...about,foto:e.target.files[0]})} type="file" inputProps={{ accept: '.jpg,.png,.jpeg',}} />
                    </Grid>  
                    <Grid item xs={12} sm={6}>
                    <Card sx={{ maxWidth:'100vw',maxHeight:'200px',}}>
                        <CardMedia
                                    component="img"
                                    height="200px"
                                    sx={{objectFit:'contain'}}
                                    image={ aboutData.length !== 0 ? process.env.REACT_APP_URL+`assets/foto/${about.foto}` : require(`../assets/noImage.png`)}
                                    alt="foto"
                                    />
                        </Card>
                    </Grid>                 
                        <Grid item xs={12}>
                                <Box
                                sx={{
                                    gap: 5,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                                >
                                <Button type='submit' variant='contained' size='large' disabled={loading}>
                                   {loading ? <CircularProgress /> : (aboutData.length ? 'Update About Us' : "Add About Us" ) }
                                </Button>
                                </Box>
                        </Grid>
                    </Grid>
                </form>
                
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
    </div>
  )
}

export default About;