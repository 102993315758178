import { Paper,Grid,Divider, TableContainer, Table, TableRow,TableHead,TableCell,TablePagination,TableBody,styled,TextField, Typography, Menu, MenuItem, IconButton, MenuList, ListItemIcon, Button } from '@mui/material'
import React,{useState,useEffect} from 'react';
// import {Link} from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { getNews,deteleBerita } from '../store/Admin';
import {DeleteForeverOutlined, MoreVert,UpdateOutlined} from '@mui/icons-material';
// import * as XLSX from 'xlsx';
import { Link,useNavigate } from 'react-router-dom';



function createData(id_berita,judul,foto_berita,link_video,isi_berita,views){
  return {id_berita,judul,foto_berita,link_video,isi_berita,views};
}

const Search = styled(TextField)({
  padding: '10px'
})

// const ExcelReport = styled(Button)(({theme})=>({
//         borderRadius: '10%',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
//         margin:'10px 10px',
//         cursor:'pointer'
// }))


const News = () => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(()=>{
    dispatch(getNews());
  },[dispatch]);
  
  const news = useSelector(state => state.Admin?.news);

  const rowsData = [];

  const [newData,setNewData] = useState([]);
  news?.forEach((item,index)=>{
    // let tanggal = item.createdAt.slice(0,10).split("-").reverse().join("-");
    // let jam = item.createdAt.slice(11,19);
    // let waktu = `${tanggal}  ${jam}`;
    rowsData.push(createData(item.id_berita,item.judul,item.foto_berita,item.link_video,item.isi_berita,item.views));
  });

  const searchData = async(val) =>{
    if(val.length > 0){
       await setNewData(rowsData.filter((i)=>{
                                              let pattern = new RegExp(val, 'gi') //setting regex utk case sensitive
                                              return i.judul.match(pattern)
                                              }));
    }else{
        await setNewData([])
    }
    
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  //=================Menu Item ============================
  const [anchorEl,setAnchorEl] = useState(null);
  const [idBerita,setIdBerita] = useState('');
  
  const handleClick = (event)=>{
        const { id_berita } = event.currentTarget.dataset;
        // console.log(id_berita)
        setIdBerita(id_berita || '');
        setAnchorEl != null ? setAnchorEl(event.currentTarget):setAnchorEl(null);
      }
  const handleClose = () => {
        setAnchorEl(null);
      };

  const handleEdit = (e) =>{
    navigate(`NewsEdit/${idBerita}`);
  }

  const handleDelete = async(e) =>{
      const confirmDelete = window.confirm('Anda yakin melakukan delete ?');

      if(confirmDelete){
          dispatch(deteleBerita(idBerita));
      }
  }

//   const exportToExcel = () => {
//     const worksheet = XLSX.utils.json_to_sheet(rowsData);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, 'Guestbook');
//     XLSX.writeFile(workbook, 'guestbook.xlsx');
//   };

  return (
    <>
    <Typography variant="h4" sx={{mb:4}} color="initial">
      News Page
    </Typography>
    <Paper sx={{width: '100%', overflow:'hidden'}}>
      <Grid container spacing={2}>
        {/* <Grid item xs={6} display="flex" alignItems="center" justifyContent="flex-start">
          <Button component={Link} to="Input" variant="outlined" sx={{m:2}}>Add Package</Button>
        </Grid> */}
        <Grid item xs={6} display="flex" alignItems="center" justifyContent="flex-start">
          <Typography sx={{m:2,fontSize:20}}>NEWS</Typography>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <Search
              variant='outlined'
              placeholder='search...'
              type='search'
              size='small'
              onInput={(e) => searchData(e.target.value)}
            />
           <Button component={Link} to="NewsAdd" variant="outlined" sx={{m:2}}>Add News</Button>
        </Grid>
      </Grid>
      <Divider />
    <TableContainer sx={{maxheight:440}} >
        <Table stickyHeader aria-label="sticky table" id="table-to-xls" >
            <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Judul</TableCell>
                  <TableCell>Foto</TableCell>
                  <TableCell>Link Video</TableCell>
                  {/* <TableCell>Jumlah Views</TableCell> */}
                  <TableCell>Option</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {(newData.length > 0 ? newData : rowsData).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,index) => (
                  <TableRow
                  hover
                    key={row.id_berita}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" xs={{midWidth:50}} scope="row">
                      {index+1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.judul}
                    </TableCell>
                    <TableCell component="th" xs={{midWidth:400}} scope="row">
                      <img src={process.env.REACT_APP_URL+`assets/foto_berita/`+row.foto_berita} alt="" style={{width:'200px',height:'200px'}} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.link_video}
                    </TableCell>
                    {/* <TableCell component="th" scope="row">
                      {row.views}
                    </TableCell> */}
                    <TableCell component="th" scope="row" >
                      <IconButton 
                          id={`menu-button${index}`}
                          data-id_berita={row.id_berita}
                          aria-controls={Boolean(anchorEl) ? `menu-button${index}`:undefined}
                          aria-haspopup="true"
                          aria-expanded={Boolean(anchorEl) ? 'true': undefined}
                          onClick={handleClick} >
                          <MoreVert />
                      </IconButton>
                      <Menu
                            id={`menu-drop${index}`}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            MenuListProps={{'aria-labelledby':`menu-button${index}`}}
                            
                          >
                            <MenuList sx={{ width: 150, maxWidth: '100%' }}>
                                  <MenuItem onClick={handleEdit} disableRipple >
                                      <ListItemIcon>
                                        <UpdateOutlined />
                                      </ListItemIcon>Update
                                    </MenuItem>
                                  <MenuItem onClick={handleDelete} disableRipple >
                                      <ListItemIcon>
                                        <DeleteForeverOutlined />
                                      </ListItemIcon>Delete
                                    </MenuItem>
                            </MenuList>
                          </Menu>
                    </TableCell>
                    
                </TableRow>
              ))}
            </TableBody>
        </Table>
    </TableContainer>
    <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
    </Paper>
    </>

  )
}

export default News