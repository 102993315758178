import React,{useEffect, useState} from 'react'
import { Typography,Grid, Card, CardMedia, CardContent, CardActions, Button, IconButton, Chip, Divider, Box, Dialog, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
import { styled } from '@mui/system';
import { AddBusiness, Delete, Edit } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import {useDispatch,useSelector} from 'react-redux';
import { getProductDisplay,deleteProduk } from '../store/produk';
import HTMLReactParser from 'html-react-parser';


const AddButton = styled(Button)(({theme})=>({
    background:theme.palette.primary.main,
    color:theme.palette.custom.white,
    paddingY:theme.spacing(1),
    minWidth:'200px',
    '&:hover': {
        backgroundColor: theme.palette.primary,
      },
    '.IconAdd':{
        color: theme.palette.custom.white
    }
}))

const ChipKategori = styled(Grid)(({theme})=>({
    display:'flex',
    justifyContent:'space-between',
    marginTop:'1.25rem',
}))

const StyledChip = styled(Chip)(({theme})=>({
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.custom.white
      },
}))

// const FavChip = styled(Chip)(({theme})=>({
//     position: 'absolute',
//     zIndex:1,
//     top:0,
//     right:0,
//     margin:'0.5rem',
//     color: theme.palette.custom.white,
//     backgroundColor:theme.palette.custom.grey,
//     '&.favorite':{
//         backgroundColor: theme.palette.primary.light,
//     },
//     '&:hover': {
//         backgroundColor: theme.palette.primary.light,
//         color: theme.palette.custom.white
//       },
// }))

const ProdukDisplay = () => {
    
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProductDisplay());
    }, [dispatch]);

    const dataProduk = useSelector(state=>state.Produk.produkDisplay);

    const [detProduct,setDetPoduct] = useState(false);

    const foto = (foto) =>{
        return process.env.REACT_APP_URL+`assets/foto/${foto}`;
      }

    // const favorite = async(id_produk) =>{
    //     await dispatch(createFavorite({'id_produk':id_produk}));
    // }

    // const formatDate = (dateString) => {
    //     const date = new Date(dateString);
    //     const options = { day: 'numeric', month: 'long', year: 'numeric' };
    //     return date.toLocaleDateString('id-ID', options);
    //   };

    const selectProduk = (id_produk) =>{
        // const data = dataProduk.find(row=>row.id_produk === id_produk);
        // setDetPoduct(!detProduct);
    }

    const HandleDeleteProduk =  async(id_produk) =>{
        const confirmed = window.confirm('Apakah Anda yakin menghapus data?');
        if(confirmed){
            await dispatch(deleteProduk(id_produk));
        }
        return;
    }

  return (
    <>
        <Grid container flexDirection={'column'} >
            <Grid container spacing={2} justifyContent={'space-between'}>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h4" sx={{mb:4}} color="initial">
                        Produk Display
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <AddButton component={Link} to={'/Produk/ProdukAddDisplay'}  >
                        <IconButton className='IconAdd'>
                            <AddBusiness />
                        </IconButton>
                        Add Display
                    </AddButton>
                </Grid>
            </Grid>
            <Grid container spacing={2} >
                    {dataProduk && dataProduk.map((row,index) => (
                        <Grid key={row.id_produk} item xs={12} sm={6} md={3}>
                        <Card sx={{ maxWidth: 345 }}>

                            <Box sx={{ position: 'relative' }}>
                                <CardMedia
                                    sx={{ height: 150, objectFit: 'contain' }}
                                    image={foto(row.foto)}
                                    title="Foto"
                                />
                                {/* <FavChip
                                    onClick={()=>favorite(row.id_produk)}
                                    // icon={row.fav === '1' ? <Check color="disabled" /> : ''}
                                    label={row.fav === '1' ? 'Favorite':'Favorite'}
                                    className={row.fav === '1' ? 'favorite':''}
                                /> */}
                            </Box>

                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div" onClick={()=>selectProduk(row.id_produk)} sx={{cursor:'pointer'}}>
                                    {row.title}
                                </Typography>
                                {/* <Typography>
                                    <CalendarMonth /> Jadwal : { new Date(JSON.parse(row.tgl_program)[0]).toLocaleDateString('id-ID',{day:'numeric'}) +' - '+ formatDate(JSON.parse(row.tgl_program)[1])}
                                </Typography> */}
                                <ChipKategori>
                                   <StyledChip label={row.kategori} color="primary" variant="outlined" /> 
                                   {/* <Chip icon={<Bookmark />} label={row.jenis} variant="outlined" /> */}
                                </ChipKategori>
                                {HTMLReactParser(row?.deskripsi_program)}
                            </CardContent>
                            <Divider />
                            <CardActions sx={{display:'flex',justifyContent:'end'}}>
                                <Button
                                  variant="text"
                                  color="primary"
                                  size="small"
                                  startIcon={<Edit />}
                                  component={Link}
                                  to={`produk_edit/${row.id_produk}`}
                                >
                                  EDIT
                                </Button>
                                {/* <Link to={`produk_edit/${row.id_produk}`}>Edit</Link> */}
                                <Button variant="text" size="small" onClick={()=>HandleDeleteProduk(row.id_produk)} startIcon={<Delete />} color="primary"> HAPUS</Button>
                            </CardActions>
                            </Card>
                        </Grid>
                    ))}     
                </Grid>
        </Grid>
       
        <Dialog
            open={detProduct}
            onClose={()=>{setDetPoduct(!detProduct)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
            
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{}}>Disagree</Button>
          <Button onClick={()=>{}} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

    </>
  )
}

export default ProdukDisplay