import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import qs from 'qs';


export const createProduct = (data) => async(dispatch)=>{
    try {
       // console.log(data.tgl_program);
        let newData = new FormData();
        
        newData.append('title',data.title);
        newData.append('deskripsi_harga',data.deskripsi_harga);
        newData.append('tgl_program',JSON.stringify(data.tgl_program));
        newData.append('jam',data.jam);
        newData.append('lokasi',data.lokasi);
        newData.append('deskripsi_program',data.deskripsi_program);
        newData.append('foto',data.foto);
        newData.append('jumlah_peserta',data.jumlah_peserta);
        newData.append('kategori',data.kategori);
        newData.append('jenis',data.jenis);

       await axios.post(`${process.env.REACT_APP_URL}produk/create_produk`,newData);
        return;

    } catch (error) {
        console.log(error)
    }
}

export const createProductDisplay = (data) => async(dispatch)=>{
    try {
       // console.log(data.tgl_program);
        let newData = new FormData();
        
        newData.append('title',data.title);
        newData.append('deskripsi_harga',data.deskripsi_harga);
        newData.append('tgl_program',JSON.stringify(data.tgl_program));
        newData.append('jam',data.jam);
        newData.append('lokasi',data.lokasi);
        newData.append('deskripsi_program',data.deskripsi_program);
        newData.append('foto',data.foto);
        newData.append('jumlah_peserta',data.jumlah_peserta);
        newData.append('kategori',data.kategori);
        newData.append('jenis',data.jenis);

       await axios.post(`${process.env.REACT_APP_URL}produk/create_produk_display`,newData);
        return;

    } catch (error) {
        console.log(error)
    }
}

export const editProduk = (data) => async(dispatch)=>{
    try {
        let newData = new FormData();
        newData.append('id_produk',data.id_produk)
        newData.append('title',data.title);
        newData.append('deskripsi_harga',data.deskripsi_harga);
        newData.append('tgl_program',JSON.stringify(data.tgl_program));
        newData.append('jam',data.jam);
        newData.append('lokasi',data.lokasi);
        newData.append('deskripsi_program',data.deskripsi_program);
        newData.append('foto',data.foto);
        newData.append('jumlah_peserta',data.jumlah_peserta);
        newData.append('kategori',data.kategori);
        newData.append('jenis',data.jenis);
       await axios.post(`${process.env.REACT_APP_URL}produk/edit_produk`,newData);
       return;
    } catch (error) {
        console.log(error);
    }
}

export const getProduct = () => async(dispatch)=>{
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL}produk/get_produk`);
        dispatch(setProduk(res.data));
    } catch (error) {
        console.log(error);
    }
}

export const getProductDisplay = () => async(dispatch)=>{
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL}produk/get_produk_display`);
        dispatch(setProdukDisplay(res.data));
    } catch (error) {
        console.log(error);
    }
}

export const getProductId = (id_product) =>async(dispatch)=>{
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL}produk/get_produk`);
        const dataId = await res.data?.find(row=>parseInt(row.id_produk) === parseInt(id_product));
        console.log(dataId);
        return dispatch(setProductId(dataId))
        // ;
    } catch (error) {
        console.log(error);
    }
}

export const createFavorite = (data) => async(dispatch) =>{
    try {
        const res = await axios.post(`${process.env.REACT_APP_URL}produk/fav_produk`,qs.stringify(data));
        dispatch(favProduk(res.data))
    } catch (error) {
        console.log(error);
    }
}

export const createLayanan = (data) =>async(dispatch)=>{
    let row = new FormData();
    row.append('judul',data.judul);
    row.append('kode',data.kode);
    row.append('id_fasilitator',data.id_fasilitator);
    row.append('ringkasan',data.ringkasan);
    row.append('deskripsi',data.deskripsi);
    row.append('foto_layanan',data.foto);
    try {
        await axios.post(`${process.env.REACT_APP_URL}produk/create_layanan`,row)
        return;
    } catch (error) {
        console.log(error);
    }
}

export const getLayanan = () => async(dispatch)=>{
    try {
        let res = await axios.get(`${process.env.REACT_APP_URL}produk/get_layanan`);
        await dispatch(setLayanan(res.data));
    } catch (error) {
        console.log(error);
    }
}

export const deleteProduk = (id_produk) => async(dispatch)=>{
    try {
        await axios.delete(`${process.env.REACT_APP_URL}produk/delete_produk/${id_produk}`);
        await dispatch(setDeleteProduk(id_produk));
    } catch (error) {
        console.log(error);
    }
}
export const deleteLayanan = (id_layanan) => async(dispatch)=>{
    try {
        await axios.delete(`${process.env.REACT_APP_URL}produk/delete_layanan/${id_layanan}`);
        await dispatch(setDeleteLayanan(id_layanan));
    } catch (error) {
        console.log(error);
    }
}


const Produk = createSlice({
    name:'produk',
    initialState:{
        produk:[],
        produkDisplay:[],
        layanan:[],
        productId:{}
    },
    reducers:{
        setProduk(state,action){
            state.produk = action.payload;
        },
        setProdukDisplay(state,action){
            state.produkDisplay = action.payload;
        },
        favProduk(state,action){
            const idProduk = parseInt(action.payload.id_produk);
            const produkIndex = state.produk.find((produk) => parseInt(produk.id_produk) === idProduk);
            produkIndex.fav = action.payload.fav
        },
        setLayanan(state,action){
            state.layanan = action.payload;
        },
        setProductId(state,action){
            state.productId = action.payload;
        },
        setDeleteProduk(state,action){
            let produk = state.produk.filter(row=> parseInt(row.id_produk) !== parseInt(action.payload));
            state.produk = produk;
        },
        setDeleteLayanan(state,action){
            let layanan = state.layanan.filter(row=> parseInt(row.id_layanan) !== parseInt(action.payload));
            state.layanan = layanan;
        }
    }
})

const {setProduk,setProdukDisplay,favProduk,setLayanan,setProductId,setDeleteProduk,setDeleteLayanan} = Produk.actions;

export default Produk;