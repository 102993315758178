import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
//import qs from 'qs';

export const getFasilitator = () =>async(dispatch)=>{
            try {
                await axios.get(`${process.env.REACT_APP_URL}fasilitator/get_fasilitator`)
                .then(async(res)=>{
                    await dispatch(setFasilitator(res.data));
                }).catch((error)=>console.log(error));
            } catch (error) {
                console.log(error);
            }
}

export const createFasilitator = (data) =>async(dispatch)=>{
    try {
        let row = await new FormData();
        row.append('nama',data.nama);
        row.append('spesialisasi',data.spesialisasi);
        row.append('deskripsi',data.deskripsi);
        row.append('foto_fas',data.foto);
        await axios.post(`${process.env.REACT_APP_URL}fasilitator/create_fasilitator`,row);
        return;
    } catch (error) {
        console.log(error)
    }
}


export const deleteFasilitator = (id_fasilitator) => async(dispatch)=>{
    try {
        await axios.delete(`${process.env.REACT_APP_URL}fasilitator/delete_fasilitator/${id_fasilitator}`);
        await dispatch(setDeleteFasilitator(id_fasilitator));
        return;
    } catch (error) {
        console.log(error);
    }
}

const Fasilitator = createSlice({
    name:'Fasilitator',
    initialState:{
        fasilitator:[]
    },
    reducers:{
        setFasilitator(state,actions){
            state.fasilitator = actions.payload;
        },
        setDeleteFasilitator(state,action){
            let fasilitator = state.fasilitator.filter(row=> parseInt(row.id_fasilitator) !== parseInt(action.payload));
            state.fasilitator = fasilitator;
        }
    },
});

const {setFasilitator,setDeleteFasilitator} = Fasilitator.actions;

export default Fasilitator;