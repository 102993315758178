import React,{useEffect} from 'react'
import { Typography,Grid, Card, CardMedia, CardContent, CardActions, Button, IconButton, Chip, Divider, Box, } from '@mui/material';
import { styled } from '@mui/system';
import { AddBusiness, Bookmark, Delete } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import {useDispatch,useSelector} from 'react-redux';
import { deleteLayanan, getLayanan } from '../store/produk';


const AddButton = styled(Button)(({theme})=>({
    background:theme.palette.primary.main,
    color:theme.palette.custom.white,
    paddingY:theme.spacing(1),
    minWidth:'200px',
    '&:hover': {
        backgroundColor: theme.palette.primary,
      },
    '.IconAdd':{
        color: theme.palette.custom.white
    }
}))

const ChipKategori = styled(Grid)(({theme})=>({
    display:'flex',
    justifyContent:'space-between',
    marginTop:'1.25rem',
}))

const Layanan = () => {
    
    const dispatch = useDispatch();

    const layanan = useSelector(state=>state.Produk.layanan);

    useEffect(() => {
        dispatch(getLayanan());
    }, [dispatch]);

    const foto = (foto) =>{
        return process.env.REACT_APP_URL+`assets/foto_layanan/${foto}`;
      }

      const handleDelete = async(id_layanan) =>{
            const confirm = window.confirm('Are you sure you want to delete ?');
            if(confirm){
                await dispatch(deleteLayanan(id_layanan));
            }
            
      }
    // const favorite = async(id_produk) =>{
    //     await dispatch(createFavorite({'id_produk':id_produk}));
    // }


  return (
    <>
        <Grid container flexDirection={'column'} >
            <Grid container spacing={2} justifyContent={'space-between'}>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h4" sx={{mb:4}} color="initial">
                        Layanan
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <AddButton component={Link} to={'Layanan_add'}  >
                        <IconButton className='IconAdd'>
                            <AddBusiness />
                        </IconButton>
                        Add Layanan
                    </AddButton>
                </Grid>
            </Grid>
            <Grid container spacing={2} >
                    {layanan && layanan.map((row,index) => (
                        <Grid key={row.id_layanan} item xs={12} sm={6} md={3}>
                        <Card sx={{ maxWidth: 345 }}>

                            <Box sx={{ position: 'relative' }}>
                                <CardMedia
                                    sx={{ height: 150, objectFit: 'contain' }}
                                    image={foto(row.foto_layanan)}
                                    title="Foto"
                                />
                            </Box>

                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                {row.judul}
                                </Typography>
                                <ChipKategori>
                                   <Chip icon={<Bookmark />} label={row.kode} variant="outlined" />
                                </ChipKategori>
                            </CardContent>
                            <Divider />
                            <CardActions sx={{display:'flex',justifyContent:'end'}}>
                                <Button variant="text" size="small" onClick={()=>handleDelete(row.id_layanan)} startIcon={<Delete />} color="primary"> HAPUS</Button>
                            </CardActions> 
                            </Card>
                        </Grid>
                    ))}     
                </Grid>
        </Grid>

    </>
  )
}

export default Layanan