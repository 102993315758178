import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, MenuItem, TextField, Typography } from '@mui/material'
import React,{useEffect, useState} from 'react'
// import { AddAPhoto } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getFasilitator } from '../store/Fasilitator';
import { createLayanan,getLayanan } from '../store/produk';
import ReactQuill from 'react-quill';

const LayananAdd = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [layanan,setLayanan] = useState({judul:'',deskripsi:'',id_fasilitator:'',kode:'',ringkasan:'',foto:''}); 
    const [loading,setLoading] = useState(false);
    const [openAlert,setOpenAlert] = useState(false);

    const kode = [
                    {kategori:'Training',kode:'training'},
                    {kategori:'Coaching',kode:'coaching'},
                    {kategori:'Consulting',kode:'consulting'},
                    {kategori:'Mentoring',kode:'mentoring'},
                    {kategori:'Out Bound',kode:'outbound'},
                    {kategori:'Event Organizier',kode:'eo'},
                     ];

    useEffect(()=>{
        dispatch(getFasilitator());
        dispatch(getLayanan());
    },[dispatch]);


    const fasilitator = useSelector(state => state.Fasilitator.fasilitator);
    const dataLayanan = useSelector(state=>state.Produk.layanan);

    const submitLayanan = async(e)=>{
        e.preventDefault();
        let cek = dataLayanan.some((row,i)=>row.kode === layanan.kode);
        if(!cek){
            await setLoading(true);
            await dispatch(createLayanan(layanan))
            .then(()=>{
                setLoading(false);
            })
            .catch(e=>{
                setLoading(false);
                console.log(e);
            }).finally(()=>{
                navigate('/Layanan');
            });
        }else{
            setOpenAlert(true);
        }
        
    }
   
    const handleClose = () =>{
        setOpenAlert(false);
    }

  return (
    <div>
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <Card>
                    <CardHeader
                    title="Add Layanan"
                    subheader=""
                    />
                <Divider></Divider>
                <CardContent>
                <form onSubmit={submitLayanan}>
                    <Grid container spacing={2}>
                        
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth label='Judul Layanan' onChange={(e)=>setLayanan({...layanan,judul:e.target.value})}  placeholder='Judul Layanan' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth onChange={(e)=>setLayanan({...layanan,foto:e.target.files[0]})} helperText="Please Insert Image" type="file" inputProps={{ accept: '.jpg,.png,.jpeg',}}  placeholder='Foto' />
                    </Grid>
                    <Grid item xs={12} sm={6}  >
                    <TextField
                        select
                        fullWidth
                        label="Select Jenis Layanan"
                        defaultValue=""
                        helperText="Please select Jenis Layanan"
                        onChange={(e)=>setLayanan({...layanan,kode:e.target.value})}
                        >
                        {kode.map((option,index) => (
                            <MenuItem key={index} value={option.kode} >
                                {option.kategori}
                            </MenuItem>
                        ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}  >
                        <TextField
                            select
                            fullWidth
                            label="Select Facilitator"
                            defaultValue=""
                            helperText="Please select Facilitator"
                            onChange={(e)=>setLayanan({...layanan,id_fasilitator:e.target.value})}
                            >
                            {fasilitator.map((option,index) => (
                                <MenuItem key={index} value={option.id_fasilitator} >
                                    {option.nama+" - "+ option.spesialisasi}
                                </MenuItem>
                            ))}
                            </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                         {/* <TextField id="outlined-multiline-static" multiline rows={5} fullWidth label="Deskripsi" placeholder='Deskripsi' onChange={(e)=>setLayanan({...layanan,deskripsi:e.target.value})} /> */}
                         <Typography variant='body1'>Deskripsi :</Typography>
                         <ReactQuill
                            value={layanan.deskripsi}
                            onChange={(value) => setLayanan({...layanan,deskripsi:value})} 
                            />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                         {/* <TextField id="outlined-multiline-static" multiline rows={5} fullWidth label="Isi Ringkasan Layanan" placeholder='Ringkasan' onChange={(e)=>setLayanan({...layanan,ringkasan:e.target.value})} /> */}
                         <Typography variant='body1'>Ringkasan Layanan :</Typography>
                         <ReactQuill
                            value={layanan.ringkasan}
                            onChange={(value) => setLayanan({...layanan,ringkasan:value})} 
                            />
                    </Grid>
                        <Grid item xs={12}>
                                <Box
                                sx={{
                                    gap: 5,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                                >
                                <Button type='submit' variant='contained' size='large' disabled={loading}>
                                   {loading ? <CircularProgress /> : ' Add Layanan'}
                                </Button>
                                </Box>
                            </Grid>
                    </Grid>
                </form>
                
                    </CardContent>
                    </Card>
                </Grid>
        </Grid>

            <Dialog
                open={openAlert}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"ALERT"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    JENIS LAYANAN YANG ANDA PILIH SUDAH ADA !
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
    </div>
  )
}

export default LayananAdd