import { Paper,Grid,Divider, TableContainer, Table, TableRow,TableHead,TableCell,TablePagination,TableBody,styled,TextField, Typography, Menu, MenuItem, IconButton, MenuList, ListItemIcon, Button } from '@mui/material'
import React,{useState,useEffect} from 'react';
// import {Link} from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { getMemberLms } from '../store/Member';
import {MoreVert,Summarize,WhatsApp} from '@mui/icons-material';
import * as XLSX from 'xlsx';

function createData(id_lms,nama,email,judul_program,telepon,tanggal){
  return {id_lms,nama,email,judul_program,telepon,tanggal};
}

const Search = styled(TextField)({
  padding: '10px'
});


const ExcelReport = styled(Button)(({theme})=>({
  borderRadius: '10%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  margin:'10px 10px',
  cursor:'pointer'
}))


const MemberLms = () => {
  
  const dispatch = useDispatch();
  
  useEffect(()=>{
    dispatch(getMemberLms());
  },[dispatch]);
  
  const member = useSelector(state => state.Member.memberLms);

  const rowsData = [];

  const [newData,setNewData] = useState([]);
  
  member.forEach((item,index)=>{
    let tanggal = item.createdAt.slice(0,10).split("-").reverse().join("-");
    let jam = item.createdAt.slice(11,19);
    let waktu = `${tanggal}  ${jam}`;
    rowsData.push(createData(item.id_lms,item.nama,item.email,item.judul_program,item.telepon,waktu))
  });

  const searchData = async(val) =>{
    if(val.length > 0){
       await setNewData(rowsData.filter((i)=>{
                                              let pattern = new RegExp(val, 'gi') //setting regex utk case sensitive
                                              return i.nama.match(pattern)
                                              }));
    }else{
        await setNewData([])
    }
    
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  //=================Menu Item ============================
  const [anchorEl,setAnchorEl] = useState(null);
  const [telepon,setTelepon] = useState('');
  // const open = Boolean(anchorEl);
  const handleClick = (event)=>{
    //  console.log(event.currentTarget);
        const { telepon } = event.currentTarget.dataset;
        setTelepon(telepon || '');
        setAnchorEl != null ? setAnchorEl(event.currentTarget):setAnchorEl(null);
      }
  const handleClose = () => {
        setAnchorEl(null);
      };

  
  const whatsappOpen = async(number) =>{
      let firstChar = number.charAt(0);
      let newNumber;
      if(firstChar === '0'){
         newNumber = "62"+ number.slice(0);
      }
      await window.open(`https://api.whatsapp.com/send?phone=${newNumber}`,'_blank');
      await handleClose();
  }


  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rowsData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'MemberLMS');
    XLSX.writeFile(workbook, 'MemberLMS.xlsx');
  };

  return (
    <>
    <Typography variant="h4" sx={{mb:4}} color="initial">
      Member LMS
    </Typography>
    <Paper sx={{width: '100%', overflow:'hidden'}}>
      <Grid container spacing={2}>
        {/* <Grid item xs={6} display="flex" alignItems="center" justifyContent="flex-start">
          <Button component={Link} to="Input" variant="outlined" sx={{m:2}}>Add Package</Button>
        </Grid> */}
        <Grid item xs={6} display="flex" alignItems="center" justifyContent="flex-start">
          <Typography sx={{m:2,fontSize:20}}>LMS Member Data</Typography>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <Search
              variant='outlined'
              placeholder='search...'
              type='search'
              size='small'
              onInput={(e) => searchData(e.target.value)}
            />
          <ExcelReport variant="outlined" onClick={exportToExcel} startIcon={<Summarize />}>
              Export
          </ExcelReport>
        </Grid>
      </Grid>
      <Divider />
    <TableContainer sx={{maxheight:440}} >
        <Table stickyHeader aria-label="sticky table" >
            <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Nama</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Telepon</TableCell>
                  <TableCell>Judul Program</TableCell>
                  <TableCell>Waktu</TableCell>
                  <TableCell>Option</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {(newData.length > 0 ? newData : rowsData).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,index) => (
                  <TableRow
                  hover
                    key={row.id_lms}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" xs={{midWidth:50}} scope="row">
                      {index+1}
                    </TableCell>
                    <TableCell component="th" xs={{midWidth:400}} scope="row">
                      {row.nama}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.telepon}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.judul_program}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.tanggal}
                    </TableCell>
                    <TableCell component="th" scope="row" >
                      <IconButton 
                          id={`menu-button${index}`}
                          data-telepon={row.telepon}
                          aria-controls={Boolean(anchorEl) ? `menu-button${index}`:undefined}
                          aria-haspopup="true"
                          aria-expanded={Boolean(anchorEl) ? 'true': undefined}
                          onClick={handleClick} >
                          <MoreVert />
                      </IconButton>
                      <Menu
                            id={`menu-drop${index}`}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            MenuListProps={{'aria-labelledby':`menu-button${index}`}}
                            
                          >
                            <MenuList sx={{ width: 150, maxWidth: '100%' }}>
                                  <MenuItem onClick={(e)=>whatsappOpen(telepon)} disableRipple >
                                      <ListItemIcon>
                                        <WhatsApp />
                                      </ListItemIcon>WhatssApp
                                    </MenuItem>
                            </MenuList>
                              
                          </Menu>
                    </TableCell>
                    
                </TableRow>
              ))}
            </TableBody>
        </Table>
    </TableContainer>
    <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
    </Paper>
    </>

  )
}

export default MemberLms