import React, { useEffect, useState } from 'react';
import Base from './layout/Base';
import Routernav from './Routernav';
import { ThemeProvider } from '@mui/material';
import Theme from './Theme';
import { useSelector } from 'react-redux';

function App() {

  const isLogin = useSelector(state => state.Admin.isLoggedIn);

  const [nowLogin,setNowLogin] = useState(isLogin);

  useEffect(()=>{
    if(isLogin){ //jika kondisi state isLogin True maka NowLogin True
      setNowLogin(isLogin); 
    }else if(isLogin !== true ){
      setNowLogin(isLogin);

      if(localStorage.getItem('isLogin') === 'true'){ //jika terjadi refresh
        setNowLogin(true);
      }
      
    }


  },[setNowLogin,isLogin,nowLogin]);

  // console.log(nowLogin+'--'+typeof(localStorage.getItem('isLogin')))
 
  //   const logoutAdmin = () =>{
  //       setNowLogin(false);
  //  }

  return (
    <>
    <ThemeProvider theme={Theme}>
       <Base isLoggedIn={nowLogin}>
         <Routernav isLoggedIn={nowLogin} />
      </Base>
    </ThemeProvider>
      
    </>
  );
}

export default App;
