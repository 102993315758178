import React,{useState} from 'react'
import { List,ListItemButton,ListItemIcon,ListItemText,Collapse,ListSubheader, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { Home,Group,ExpandLess,ExpandMore, RadioButtonUnchecked, Groups, Ballot, Attribution, Apps,InfoRounded,Newspaper } from '@mui/icons-material';

const SideMenu = () => {

    const [open,setOpen] = useState({openId:0});

    let menu =  [
                    {id_menu:1,link:'/',title:'Home',icon:<Home />,key:false,child:[]},
                    {id_menu:7,link:'/About',title:'About',icon:<InfoRounded />,key:false,child:[]},
                    {id_menu:8,link:'/News',title:'News',icon:<Newspaper />,key:false,child:[]},
                    {id_menu:2,link:false,title:'Guest',icon:<Group />,key:2,child:[
                                                                                    {id_child:1,link:'/guestbook',title:'Guest Book'},
                                                                                    {id_child:2,link:'/guest_prospek',title:'Guest Prospek'},
                                                                                  ],    
                    },
                    {id_menu:3,link:false,title:'Produk',icon:<Ballot />,key:3,child:[
                                                                                      {id_child:1,link:'produk',title:'Produk'},
                                                                                      {id_child:2,link:'/produk/ProdukDisplay',title:'Display Produk'},
                                                                                    ]},
                    {id_menu:4,link:false,title:'Member',icon:<Groups />,key:4,child:[
                                                                                      {id_child:1,link:'Member',title:'Member'},
                                                                                      {id_child:2,link:'/Member/Member_lms',title:'Member LMS'},
                                                                                    ]},
                    {id_menu:5,link:false,title:'Fasilitator',icon:<Attribution />,key:5,child:[
                                                                                      {id_child:1,link:'fasilitator',title:'Data Fasilitator'},
                                                                                    ]},
                    {id_menu:6,link:false,title:'Manage Layanan',icon:<Apps />,key:6,child:[
                                                                                      {id_child:1,link:'Layanan',title:'Layanan'},
                                                                                    ]},

                ];
                                        

    const handleClick = (id) => {
             open.openId === id ? setOpen({...open,openId:0}) : setOpen({...open,openId:id})
    };



  return (
    <>
          <div>
         <List>
                <ListSubheader>{`Menu`}</ListSubheader>
                {menu.map((row,index)=>(
                    <div key={index}>
                        <ListItemButton component={Link} to={row.link} onClick={()=>handleClick(row.key)}>
                        <ListItemIcon>
                            {row.icon}
                        </ListItemIcon>
                        <ListItemText primary={row.title} />
                        {row.key ? open.openId === row.id_menu ? <ExpandLess /> : <ExpandMore />: ''}
                        </ListItemButton>
                        <Collapse in={open.openId === row.key} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {row.child.map((child,index)=>(
                                        <div key={index}>
                                            <ListItemButton sx={{ pl: 4 }} component={Link} to={child.link}>
                                                <ListItemIcon>
                                                    <RadioButtonUnchecked />
                                                </ListItemIcon>
                                                <ListItemText primary={child.title} />
                                            </ListItemButton>
                                        </div>
                                ))}
                            </List>
                        </Collapse>
                    </div>
                    
                ))}
                
            </List>
    </div>
    <Box sx={{position:'absolute',bottom:16,left:30}}>
            <img src={require('../assets/orbit_footer.png')} alt="Logo" style={{ width: '150px', height: '150px' }} />
      </Box>
    </>

  )
}

export default SideMenu