import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, MenuItem, TextField, Typography } from '@mui/material'
import React,{useEffect, useState} from 'react'
// import { AddAPhoto } from '@mui/icons-material';
// import InputRupiah from '../component/InputRupiah';
import { useDispatch, useSelector } from 'react-redux';
import {createProductDisplay,getProductDisplay,editProduk} from '../store/produk';
import { useNavigate,useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

//===========================react quill==================================
const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'align',
  'list',
  'link',
  // 'image',
];

const modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'align': [] }], // toolbar rata kanan dan rata kiri
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ['link', 'image'],
    ['clean'],
  ],
};
//=================================================================

const ProdukAddDisplay = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [produk,setProduk] = useState(
                                    {
                                    title:'',
                                    deskripsi_harga:'',
                                    tgl_program:[],
                                    jam:'',
                                    lokasi:'',
                                    foto:'',
                                    deskripsi_program:'',
                                    jumlah_peserta:0,
                                    kategori:'',
                                    jenis:''
                                   }
                                ); 
    const [loading,setLoading] = useState(false);

    const kategori = [
                        {kategori:'Training',kode:'training'},
                        {kategori:'Coaching',kode:'coaching'},
                        {kategori:'Consulting',kode:'consulting'},
                        {kategori:'Mentoring',kode:'mentoring'},
                        {kategori:'Out Bound',kode:'outbound'},
                        {kategori:'Event Organizier',kode:'eo'},
                     ];

    // const createRupiah = (data,atribut) =>{
    //                     switch (atribut) {
    //                       case 'harga':
    //                         setProduk({...produk,harga:data});
    //                         break;
    //                       default:
    //                         break;
    //                     }
    //                   }
    
    const createProduk = async(e) =>{
        e.preventDefault();
        await setLoading(true);
        return new Promise((resolve, reject) =>{
            dispatch(createProductDisplay(produk)).then(()=>{
                setLoading(false);
                resolve();
            })
            .catch((error)=>{
               reject(error); 
            })
            .finally(()=>{
                navigate('/produk/ProdukDisplay');
            })
        })
    }

    //======================================edit ========================================

    const handleEditProduk = async(e) =>{
        e.preventDefault();
        await setLoading(true);
        produk.id_produk = await params.id_produk;
        // produk.deskripsi_harga = await convertToHTML(produk.deskripsi_harga);
        console.log(produk);
        
        await dispatch(editProduk(produk));
        await setLoading(false);
        navigate('/produk');
    }

    useEffect(() => {
        const fetchData = async () => {
          await dispatch(getProductDisplay());
        };
        fetchData();
      }, [dispatch]);

      const product = useSelector(state=>state.Produk.produk);

      useEffect(() => {
        if (params.id_produk && product) {
          const productId = product.find(
            (row) => parseInt(row.id_produk) === parseInt(params.id_produk)
          );
      
          if (productId) {
            setProduk({
              title: productId.title,
              deskripsi_harga: productId.deskripsi_harga,
              tgl_program: JSON.parse(productId.tgl_program),
              jam: productId.jam,
              lokasi: productId.lokasi,
              foto: productId.foto,
              deskripsi_program: productId.deskripsi_program,
              jumlah_peserta: productId.jumlah_peserta,
              kategori: productId.kategori,
              jenis: productId.jenis,
            });
          } else {
            // Nilai produk default jika productId tidak ditemukan
            setProduk({
              title: '',
              deskripsi_harga: '',
              tgl_program: [],
              jam: '',
              lokasi: '',
              foto: '',
              deskripsi_program: '',
              jumlah_peserta: 0,
              kategori: '',
              jenis: '',
            });
          }
        }
      }, [params.id_produk, product]);





   
  return (
    <div>
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <Card>
                    <CardHeader
                    title={params.id_produk ? 'Edit Produk Display' : "Add Produk Display" }
                    subheader=""
                    />
                <Divider></Divider>
                <CardContent>
                <form onSubmit={params.id_produk ? handleEditProduk : createProduk}>
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth label='Title' onChange={(e)=>setProduk({...produk,title:e.target.value})} value={produk.title}  placeholder='Title'  />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography variant='body1'>Deskripsi Program :</Typography>
                        <ReactQuill
                             formats={formats}
                             modules={modules}
                            value={produk.deskripsi_program}
                            onChange={(value) => setProduk((prevProduk) => ({
                                ...prevProduk,
                                deskripsi_program: value
                              }))} 
                            sx={{minHeight:'10em'}}
                            />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth label='Foto' helperText="Pilih Foto Program" onChange={(e)=>setProduk({...produk,foto:e.target.files[0]})} type="file" inputProps={{ accept: '.jpg,.png,.jpeg',}}  placeholder='Foto' />
                    </Grid>
                    <Grid item xs={12} sm={6}  >
                    <TextField
                        select
                        fullWidth
                        label="Kategori Produk"
                        defaultValue=""
                        helperText="Please select your category"
                        value={produk.kategori}
                        onChange={(e)=>setProduk({...produk,kategori:e.target.value})}
                        >
                        {kategori.map((option,index) => (
                            <MenuItem key={index} value={option.kode} >
                                {option.kategori}
                            </MenuItem>
                        ))}
                        </TextField>
                    </Grid>                  
                        <Grid item xs={12}>
                                <Box
                                sx={{
                                    gap: 5,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                                >
                                <Button type='submit' variant='contained' size='large' disabled={loading}>
                                   {loading ? <CircularProgress /> : (params.id_produk ? 'Edit Produk' : "Add Produk Display" ) }
                                </Button>
                                </Box>
                        </Grid>
                    </Grid>
                </form>
                
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
    </div>
  )
}

export default ProdukAddDisplay