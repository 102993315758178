import React,{lazy,Suspense} from 'react'
import {Routes,Route,Outlet, Navigate} from 'react-router-dom' 
// import Home from './pages/Home';
import Guestbook from './pages/Guestbook'
import GuestProspek from './pages/GuestProspek';
import Produk from './pages/Produk';
import ProdukAdd from './pages/ProdukAdd';
import Member from './pages/Member';
import Fasilitator from './pages/Fasilitator';
import FasilitatorAdd from './pages/FasilitatorAdd';
import Layanan from './pages/Layanan';
import LayananAdd from './pages/LayananAdd';
import Login from './pages/Login';
import MemberLms from './pages/MemberLms';
import ProdukDisplay from './pages/ProdukDisplay';
import ProdukAddDisplay from './pages/ProdukAddDisplay';
import About from './pages/About';
import News from './pages/News';
import NewsAdd from './pages/NewsAdd';

const Home = lazy(() => import('./pages/Home'));

const Routernav = ({isLoggedIn}) => {

  return (

        <Routes>
            <Route path="/" element={ isLoggedIn  ? <Suspense fallback={<div>Loading...</div>}><Home /></Suspense> : <Navigate to="/Login" />} />
            <Route path="/Home" element={isLoggedIn  ? <Suspense fallback={<div>Loading...</div>}><Home /></Suspense> : <Navigate to="/Login" />} />
            <Route path="/About" element={isLoggedIn  ? <Suspense fallback={<div>Loading...</div>}><About /></Suspense> : <Navigate to="/Login" />} />
            <Route path="/Login" element={isLoggedIn ? <Home /> :  <Login />} />
            <Route path="/guestbook" element={ isLoggedIn  ? <Guestbook /> : <Navigate to="/Login" />  } />
            <Route path="/guest_prospek" element={  isLoggedIn  ? <GuestProspek /> : <Navigate to="/Login" /> } />
            <Route path ="News" element={<Outlet /> }>
                <Route index element={isLoggedIn  ? <Suspense fallback={<div>Loading...</div>}><News /></Suspense> : <Navigate to="/Login" />} />
                <Route path='NewsAdd' element={<NewsAdd />} />
                <Route path='NewsEdit/:id_berita' element={<NewsAdd />} />
            </Route>
            <Route path="produk" element={<Outlet />}  >
                <Route index element={ isLoggedIn ?  <Produk /> : <Navigate to="/Login" />  } />
                <Route path='ProdukDisplay' element={<ProdukDisplay />} />
                <Route path='ProdukAddDisplay' element={<ProdukAddDisplay />} />
                <Route path='ProdukAdd' element={<ProdukAdd />} />
                <Route path='produk_edit/:id_produk' element={<ProdukAdd />} />
            </Route>
            <Route path="Member" element={<Outlet />}  >
                <Route index element={ isLoggedIn ? <Member /> : <Navigate to="/Login" /> } />
                <Route path="Member_lms" element={ isLoggedIn ? <MemberLms /> : <Navigate to="/Login" /> } />
            </Route>
            <Route path="Fasilitator" element={<Outlet />}  >
                <Route index element={<Fasilitator />} />
                <Route path='Fasilitator_add' element={<FasilitatorAdd />} />
            </Route>
            <Route path="Layanan" element={<Outlet />}  >
                <Route index element={<Layanan />} />
                <Route path='Layanan_add' element={<LayananAdd />} />
            </Route>

        </Routes>
  )
}

export default Routernav;