import { Box, Button, CircularProgress, Divider, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { createFasilitator } from '../store/Fasilitator';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';


//===========================react quill==================================
const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'align',
    'list',
    'link',
    // 'image',
  ];
  
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'align': [] }], // toolbar rata kanan dan rata kiri
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link', 'image'],
      ['clean'],
    ],
  };
//=================================================================


const FasilitatorAdd = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fas,setFas] = useState({nama:'',spesialisasi:'',deskripsi:'',foto:''});
    const [loading,setLoading] = useState(false);
    
    const submitData = async(e)=>{
        e.preventDefault();
        await setLoading(true);
        await dispatch(createFasilitator(fas));
        await setLoading(false);
        navigate('/fasilitator');
    }

    return (
    <div>
        <Grid container>
            <Grid item xs={12} sm={12} md={12} >
                <Paper elevation={3} sx={{height:'80vh'}} >
                    <Box sx={{padding:3}}>
                        <Typography variant='h5'>Add Fasilitator</Typography>
                    </Box>
                    <Divider />
                    <Box py={3} px={3}>
                        <form onSubmit={submitData}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth label="Nama" placeholder='Nama' onChange={(e)=>setFas({...fas,nama:e.target.value})}  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth label="Spesialisasi" placeholder='Spesialisasi' onChange={(e)=>setFas({...fas,spesialisasi:e.target.value})}  />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <ReactQuill
                                            formats={formats}
                                            modules={modules}
                                            onChange={(value) => setFas((prev) => ({
                                                ...prev,
                                                deskripsi: value
                                            }))} 
                                            sx={{minHeight:'10em'}}
                                            />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant='body1'>Foto : </Typography>
                                    <TextField fullWidth placeholder='Foto' type="file" inputProps={{ accept: '.jpg,.png,.jpeg'}} onChange={(e)=>setFas({...fas,foto:e.target.files[0]})}  />
                                </Grid>
                                <Grid item xs={12} sm={12} mt={3}>
                                    <Button type="submit" variant="contained" disabled={loading}>
                                        {loading ? <CircularProgress /> : 'Tambah Fasilitator'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form> 
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    </div>
  )
}

export default FasilitatorAdd