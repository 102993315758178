import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios';

export const getMember = () =>async(dispatch)=>{
        await axios.get(`${process.env.REACT_APP_URL}member/get_member`)
        .then((res)=>{
            dispatch(setMember(res.data));
        }).catch(error=>console.log(error));
}

export const getMemberLms = () =>async(dispatch)=>{
    const res = await axios.get(`${process.env.REACT_APP_URL}member/getMemberLms`);
    dispatch(setMemberLms(res.data));
}


const Member = createSlice({
    name:'member',
    initialState:{
                  member:[],
                  memberLms:[]
                },
    reducers:{
        setMember(state,actions){
            state.member = actions.payload;
        },
        setMemberLms(state,actions){
            state.memberLms = actions.payload;
        }
    }
});

const {setMember,setMemberLms} = Member.actions;

export default Member;