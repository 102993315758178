import { createTheme } from '@mui/material'

const Theme = createTheme({
    palette:{
        custom:{
            white:'#ffffff',
            grey:'#c0c0c0'
        }
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 600, // Atur nilai ini sesuai dengan definisi layar mobile
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
})

export default Theme