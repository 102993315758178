import React,{useState} from 'react';
import { Box, AppBar, Toolbar, IconButton, Typography, Drawer, CssBaseline, Grid, Tooltip, Avatar, MenuItem, Menu, ListItemIcon } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
import MenuIcon  from '@mui/icons-material/Menu'
import { styled } from '@mui/material/styles';
import SideMenu from './SideMenu';
import { Logout } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authLogin } from '../store/Admin';
const drawerWidth = 240;

// const settings = ['Dashboard', 'Logout'];

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
transition: theme.transitions.create('width', {
easing: theme.transitions.easing.sharp,
duration: theme.transitions.duration.leavingScreen,
}),
  overflowX: 'hidden',
  width: theme.spacing(0),
  [theme.breakpoints.up('sm')]: {
  width: theme.spacing(9),
  },
  [theme.breakpoints.up('md')]: {
  width: theme.spacing(7),
  },
});


const Sidebar = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& ::-webkit-scrollbar': {
    display: 'none',
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  [theme.breakpoints.down('sm')]: { //pada saat tampilan mobile maka posisi menu menjadi absolute
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
  },
  ...theme.mixins.toolbar,
}),
);

const AppBars = styled(AppBar)(({theme})=>({
zIndex: theme.zIndex.drawer + 2,
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBarContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: drawerWidth,
  [theme.breakpoints.down('sm')]: {
    '& h6': {
      display: 'none',
    },
  },
}));


const Base = ({children,isLoggedIn}) => {

  const [open,setOpen] = useState(true);

  const navigate = useNavigate();
  
  const openHandler = ()=>{
      if(window.innerWidth <= 600){
        setOpen(false);
      }
  }

  //====================menu=============
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const dispatch = useDispatch();

  const handleLogout = async() =>{
      await handleCloseUserMenu();
      await localStorage.removeItem('isLogin');
      await dispatch(authLogin(false));
      navigate('/login')
  }

  return (
    <>
      <Box sx={{display:'flex'}}>
        <CssBaseline />
          {isLoggedIn ? (
          <>
          <AppBars position="fixed" color="primary">
                <Toolbar>
                  <AppBarContainer>
                    <Typography variant="h6">
                      Go-Orbit Panel
                    </Typography>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setOpen(!open)}>
                      <MenuIcon />
                    </IconButton>
                  </AppBarContainer>
                  <Box sx={{ flexGrow: 1 }} /> {/* Menggunakan flexGrow untuk mengisi ruang kosong */}
                  <Box sx={{ flexGrow: 0, marginRight: '16px' }}> {/* Mengatur margin kanan pada kotak */}
                    <Grid container alignItems="center" justifyContent="flex-end">
                      <Grid item>
                        <Tooltip title="Open settings" placement="bottom-end">
                          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar />
                          </IconButton>
                        </Tooltip>
                        <Menu
                              sx={{ mt: '45px' }}
                              id="menu-appbar"
                              anchorEl={anchorElUser}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              keepMounted
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              open={Boolean(anchorElUser)}
                              onClose={handleCloseUserMenu}
                            >
                              <MenuItem onClick={handleLogout}>
                                  <ListItemIcon>
                                    <Logout /> {/* Ganti LogoutIcon dengan ikon yang sesuai */}
                                  </ListItemIcon>
                                  <Typography textAlign="center">Log Out</Typography>
                                </MenuItem>
                            </Menu>
                      </Grid>
                    </Grid>
                  </Box>
                </Toolbar>
              </AppBars>
                <Sidebar
                  variant="permanent"
                  anchor="left"
                  open={open}
                >
                  <Toolbar />
                <Box sx={{overflow:'auto',}}>
                  <SideMenu />
                </Box>
                </Sidebar> </> ) : '' }
          <Box component="main" onClick={()=>{openHandler()}} sx={{ flexGrow: 1, p: 3,  backgroundColor: (theme) =>
                theme.palette.grey[100],
                height: '100vh',
                overflow: 'auto',
                '@media (max-width: 600px)': {
                  opacity: open ? 0.3 : 1, // Menggunakan opasitas konten sesuai dengan keadaan menu saat layar mobile
                },
                 }}>
            <DrawerHeader />
            {children}
          </Box>
      </Box>
    </>
  );
}

export default Base;
